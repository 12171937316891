// current page host
let baseURI = window.location.host;
let gaHitCallback = require('gaHitCallback');

document.addEventListener('click', function(e) {

  if (e.which != 1) return; // track only left-mouse clicks
  if (e.defaultPrevented) return;

  // abandon if no active link or link within domain
  let link = e.target.closest && e.target.closest("a");
  if (!link || (baseURI == link.host && !link.hasAttribute('data-track-outbound'))) return;

  // invalid or blank target
  if (!~["_self", "_top", "_parent"].indexOf(link.target)) return;

  if (e.shiftKey || e.ctrlKey || e.altKey) return;

  // cancel event and record outbound link
  e.preventDefault();
  let href = link.href;

  // GA4
  // window.ga('send', 'event', {
  //   eventCategory: 'outbound',
  //   eventAction: 'click',
  //   eventLabel: href,
  //   hitCallback: gaHitCallback(loadPage)
  // });

  // redirect to outbound page
  function loadPage() {
    document.location = href;
  }

});
