let Spinner = require('client/spinner');

for(let elem of document.querySelectorAll('[data-action-login]')) {
  for(let cls of elem.classList) {
    if (cls.endsWith('_unready')) {
      elem.classList.remove(cls);
    }
  }
}


document.addEventListener("click", function(event) {
  if (!event.target.hasAttribute('data-action-login')) {
    return;
  }

  let options = { email: event.target.getAttribute('data-action-login') };

  event.preventDefault();
  login(options);

});

function login(options) {
  let modal = new Modal({ hasClose: false, mixClass: 'login-modal' });
  let spinner = new Spinner();
  modal.setContent(spinner.elem);

  spinner.start();

  require.ensure('auth/client/authModal', function() {
    modal.remove();
    let AuthModal = require('auth/client/authModal');
    new AuthModal(options);
  }, 'authClient');

}

module.exports = login;
