let loadScript = require('./loadScript');
let Recaptcha = require('./recaptcha');

module.exports = async function() {
  let submitButtons = document.querySelectorAll('[data-recaptcha-submit]');
  if (!submitButtons.length) return;

  for(let button of submitButtons) {
    button.disabled = true;
  }

  await loadScript();

  for(let button of submitButtons) {
    let form = button.form;
    
    let recaptcha = new Recaptcha(form);
    
    form.onsubmit = async (event) => {
      event.preventDefault();
      await recaptcha.validateForm(form);
              
      if (form.checkValidity()) {
        form.submit();
      } else {
        form.reportValidity();
      }
    }

    button.disabled = false;
  }

}