let notification = require('client/notification');

document.addEventListener("click", function(event) {
  let target = event.target.closest('[data-banner-close]');
  if (!target) {
    return;
  }

  localStorage["hideBanner" + target.dataset.bannerClose] = Date.now();
  new notification.Success(target.dataset.bannerCloseMessage);

  target.parentNode.remove();

});
