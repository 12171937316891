
// assuming weekdays, from, to are UTC
// shift them to given timezone offset
function adjustWeekRange(weekDays, from, to, offset) {

  offset = +offset;

  if (from.split) from = from.split(':').map(Number);
  if (to.split) to = to.split(':').map(Number);
  // apply timezone shift

  // convert to minutes to shift fractional (04:30) zones
  from = from[0] * 60 + from[1] + offset;
  to = to[0] * 60 + to[1] + offset;

  // go day back
  if (from < 0) {
    from += 24 * 60;
    to += 24 * 60;
    weekDays = weekDays.map(weekDay => weekDay === 1 ? 7 : (weekDay - 1)); // decrease day
  } else if (from >= 24 * 60) {
    from -= 24 * 60;
    to -= 24 * 60;
    weekDays = weekDays.map(weekDay => weekDay === 7 ? 1 : (weekDay + 1)); // increase day
  }

  // from is adjusted above, but to is not
  if (to > 24 * 60) to -= 24 * 60;
  if (to < 0) to += 24 * 60;


  let pad = num =>
    num === 0 ? '00' :
      num < 10 ? ('0' + num) :
        num;

  from = [Math.floor(from / 60), from % 60].map(pad).join(':');
  to = [Math.floor(to / 60), to % 60].map(pad).join(':');

  return [weekDays, from, to];

}

module.exports = adjustWeekRange;