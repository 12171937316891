require('core-js/fn/string/match-all');

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(string) {
    let index = arguments.length < 2 ? 0 : arguments[1];

    return this.slice(index).indexOf(string) === 0;
  };
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(string) {
    let index = arguments.length < 2 ? this.length : arguments[1];
    let foundIndex = this.lastIndexOf(string);
    return foundIndex !== -1 && foundIndex === index - string.length;
  };
}

if (!String.prototype.includes) {
  String.prototype.includes = function(string, index) {
    if (typeof string === 'object' && string instanceof RegExp) throw new TypeError("First argument to String.prototype.includes must not be a regular expression");
    return this.indexOf(string, index) !== -1;
  };
}


if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ');
    if (this.length >= targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}
