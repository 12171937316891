let moment = require('engine/moment-with-locale');

let getTimeZoneName = require('./getTimeZoneName');

class DateLocalElement extends HTMLElement {


  constructor() {
    super();

    this.render();
  }

  connectedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['timestamp', 'format', 'with-zone', 'to', 'from'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.render();
  }

  format(date, format, withZone) {

    let formatted = moment(date).utcOffset(-new Date().getTimezoneOffset()).format(format);

    if (withZone) {
      formatted += ' ' + getTimeZoneName();
    }

    return formatted;
  }


  // from="..." to="..."
  render() {

    // firefox throws error with infinite recursion otherwise
    let params = ['timestamp', 'from', 'to', 'format'].map(f => this.getAttribute(f)).join(':');
    if (this.lastParams === params) {
      return;
    }
    this.lastParams = params;

    if (this.hasAttribute('with-zone')) {
      let zone = getTimeZoneName();
      this.setAttribute('data-tooltip', "Время в вашем часовом поясе,<br>по данным браузера это " + zone + ".");
    }

    if (this.getAttribute('timestamp')) {
      // console.log(this.getAttribute('timestamp'));
      if (this.getAttribute('timestamp')[0] === '{') return; // Angular
      this.innerHTML = this.format(this.getAttribute('timestamp'), this.getAttribute('format'), this.hasAttribute('with-zone'));
      return;
    }

    let fromTo = [];

    if (this.getAttribute('from')[0] === '{' || this.getAttribute('to')[0] === '{') return; // Angular

    let from = new Date(this.getAttribute('from'));
    let to = new Date(this.getAttribute('to'));

    let fromFormat = this.getAttribute('format');
    let toFormat = this.getAttribute('format');

    if (from.getFullYear() === to.getFullYear()) {
      fromFormat = fromFormat.replace(/ YY(YY)?$/g, '');
    }

    // if day is not the same we display range
    if (from.getFullYear() !== to.getFullYear() || from.getMonth() !== to.getMonth() || from.getDate() !== to.getDate())
      fromTo.push(this.format(from, fromFormat));

    fromTo.push(this.format(to, toFormat, this.hasAttribute('with-zone')));

    this.innerHTML = fromTo.join(' – ');
  }

}

window.DateLocalElement = DateLocalElement;

window.customElements.define('date-local', DateLocalElement);
