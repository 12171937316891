let moment = require('engine/moment-with-locale');

let getTimeZoneName = require('./getTimeZoneName');

class DateExceptElement extends HTMLElement {

  constructor() {
    super();

    this.render();
  }

  connectedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['except'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.render();
  }

  format(date, format, withZone) {

    let formatted = moment(date).utcOffset(-new Date().getTimezoneOffset()).format(format);

    if (withZone) {
      formatted += ' ' + getTimeZoneName();
    }

    return formatted;
  }


  // from="..." to="..."
  render() {
    // firefox throws error with infinite recursion otherwise
    let params = ['except'].map(f => this.getAttribute(f)).join(':');
    if (this.lastParams === params) {
      return;
    }
    this.lastParams = params;

    if (this.getAttribute('except')[0] === '{') return; // Angular

    if (!this.getAttribute('except')) { // no dates to skip
      return;
    }

    // dates in browser-local timezone
    let datesExcept = this.getAttribute('except').split(',').map(d => new Date(d));

    // (кроме 1, 2 дек, 4 янв)
    let output = [];
    for (let i = 0; i < datesExcept.length; i++) {
      let date = datesExcept[i];
      let nextDate = datesExcept[i+1];
      let needsMonth = !nextDate || nextDate.getMonth() != date.getMonth();
      let outputDate = moment(date).utcOffset(-new Date().getTimezoneOffset()).format(needsMonth ? 'D MMM' : 'D');
      output.push(outputDate);
    }

    this.innerHTML = `(кроме ${output.join(', ')})`;
  }

}

window.DateExceptElement = DateExceptElement;

window.customElements.define('date-except', DateExceptElement);
