// require('client/trackjs');
require('client/polyfill');

//exports.init = require('./init');
exports.login = require('./login');

require('./logout');

exports.resizeOnload = require('./resizeOnload');
require('./layout');
require('./sitetoolbar');
require('engine/sidebar/client');
require('./navigationArrows');
require('./hover');
require('./trackLinks');
require('./spoiler');
require('./bannerBottom');

// must use CommonsChunkPlugin
// to ensure that other modules use exactly this (initialized) client/notify
require('client/notification').init();

require('client/recaptcha').initForms();

require('client/dateTime/dateLocalElement');
require('client/dateTime/dateExceptElement');
require('client/dateTime/weekRangeElement');
require('client/price/priceCurrencyElement');
