let loadScript = require('./loadScript');

module.exports = class Recaptcha {
  constructor(elem) {
    this.elem = elem;

    this.renderPromise = new Promise((resolve, reject) => {
      this.renderPromiseResolve = resolve;
      this.renderPromiseReject = reject;
    });

    this.render();
  }

  async render() {
    if (!window.RECAPTCHA_ID) return;

    // console.log('RENDER');
    
    if (this.widgetId !== undefined) return; // already rendered

    await loadScript();

    let container = document.createElement('div');
    this.elem.append(container);
    this.widgetId = grecaptcha.render(container, {
      sitekey: window.RECAPTCHA_ID,
      size: 'invisible',
      callback: this.renderPromiseResolve,
    });

    // console.log('RENDERED', this.widgetId);
  }

  async execute() {
    if (!window.RECAPTCHA_ID) return '';
    await this.render();

    let token = grecaptcha.getResponse(this.widgetId);

    // console.log("TOKEN", token);
    if (token) {
      // already resolved
      return token;
    }

    // prevent body scroll while modal captcha is open
    // document.body.classList.add('overflow-hidden');
    // this.renderPromise.then(() => document.body.classList.remove('overflow-hidden'));

    grecaptcha.execute(this.widgetId);

    // console.log('EXECUTED', this.widgetId);
    return this.renderPromise;
  }

  async validateForm(form) {
    if (!window.RECAPTCHA_ID) return;

    let token = await this.execute();

    // console.log("VALIDATE TOKEN", token);
    let input = form.elements['g-recaptcha-response'];
    if (!input) {
      input = document.createElement('input');
      input.name = 'g-recaptcha-response';
      input.type = 'hidden';
      form.append(input);
    }

    input.value = token;
  }
};
