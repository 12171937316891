

let hasFormatToParts = new Intl.DateTimeFormat([], { timeZoneName: 'short'}).formatToParts;

module.exports = function() {

  // from https://github.com/moment/luxon/blob/e77f74f27fae1237a5b59b1df15e4114215f665c/src/impl/util.js
  if (hasFormatToParts) { // Edge doesn't have it
    return new Intl.DateTimeFormat([], { timeZoneName: 'short'}).formatToParts(new Date()).find(t => t.type == 'timeZoneName').value;
  }

  let browserUtcOffset = -new Date().getTimezoneOffset();

  let timeZoneShort = new Date().toLocaleTimeString([], {timeZoneName: 'short'}).split(' ');

  if (timeZoneShort[0].match(/[+-]/)) { // can be "GMT+3 xiayu13:00"
    timeZoneShort = timeZoneShort[0];
  } else if (timeZoneShort[timeZoneShort.length-1].match(/[+-]/)) { // or "12.23.32 GMT+3"
    timeZoneShort = timeZoneShort[timeZoneShort.length-1];
  } else if (timeZoneShort[timeZoneShort.length-1].match(/[A-Z]{2,}/)) {
    timeZoneShort = timeZoneShort[timeZoneShort.length - 1]; // or "12:32 PDT"
  } else {
    timeZoneShort = 'GMT';
    if (browserUtcOffset > 0) timeZoneShort += '+' + browserUtcOffset/60;
    if (browserUtcOffset < 0) timeZoneShort += '-' + -browserUtcOffset/60;
  }

  return timeZoneShort;
};
