// @see https://stackoverflow.com/questions/48224799/test-invisible-recaptcha hot to test captcha

async function init() {
  if (!window.RECAPTCHA_ID) return;

  if (init.promise) return init.promise;

  init.promise = new Promise((resolve, reject) => {
    window.recaptchaCallback = resolve;
    let script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit`;
    script.onerror = reject;
    document.head.appendChild(script);
  });

  return init.promise;
}

exports.Recaptcha = require('./recaptcha');

exports.initForms = require('./initForms');