let promise;

module.exports = async function loadScript() {
  if (!window.RECAPTCHA_ID) return;

  if (promise) return promise;

  promise = new Promise((resolve, reject) => {
    window.recaptchaCallback = resolve;
    let script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit`;
    script.onerror = reject;
    document.head.appendChild(script);
  });

  return promise;
}

