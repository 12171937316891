let moment = require('engine/moment-with-locale');
const getSymbolFromCurrency = require('currency-symbol-map');

class PriceCurrencyElem extends HTMLElement {

  constructor() {
    super();

    this.render();
  }

  connectedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['amount', 'convert-to', 'only'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.render();
  }

  addCurrencySign(amount, currency) {
    if (['CNY'].includes(currency)) {
      return amount + getSymbolFromCurrency(currency);
    } else {
      return getSymbolFromCurrency(currency) + amount;
    }
  }

  // from="..." to="..."
  render() {
    // firefox throws error with infinite recursion otherwise
    let params = PriceCurrencyElem.observedAttributes.map(f => this.getAttribute(f)).join(':');
    if (this.lastParams === params) {
      return;
    }
    this.lastParams = params;

    let amount = +this.getAttribute('amount');

    let output = {};

    if (window.countryCode == 'am') { // armenia
      output.amount = '֏' + Math.round(amount * window.rateShopTo.AMD);

      output.convertTo = this.addCurrencySign(amount, window.shopCurrency);
    } else {
      if (window.shopCurrency == 'RUB') {
        output.amount = amount + 'р';
        if (this.getAttribute('convert-to')) {
          let convertToCurrency = this.getAttribute('convert-to');
          output.convertTo = this.addCurrencySign(Math.round(amount * window.rateShopTo[convertToCurrency]), convertToCurrency);
        }
      } else {
        output.amount = this.addCurrencySign(amount, window.shopCurrency);
        output.convertTo = this.addCurrencySign(Math.round(amount * window.rateShopTo[window.localCurrency]), window.localCurrency);
      }
    }

    this.innerHTML = this.getAttribute('only') == 'amount' ? output.amount :
      this.getAttribute('only') == 'convert-to' ? output.convertTo :
      (
        output.amount + (output.convertTo ? ` (≈${output.convertTo})` : '')
      );

  }

}

window.PriceCurrencyElem = PriceCurrencyElem;

window.customElements.define('price-currency', PriceCurrencyElem);
