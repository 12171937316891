const disqusEmbedUrl = '//' + window.disqus_shortname + '.disqus.com/embed.js';
const disqusCountUrl = '//' + window.disqus_shortname + '.disqus.com/count.js';

function loadDisqus() {
  const disqusElem = document.getElementById('disqus_thread');

  if (disqusElem.classList.contains('disqus-loading')) {
    return;
  }

  disqusElem.classList.add('disqus-loading');
  const spinner = new Spinner({
    size: 'large'
  });
  disqusElem.append(spinner.elem);
  spinner.start();

  const s = document.createElement('script');
  s.src = disqusEmbedUrl;
  s.setAttribute('data-timestamp', +new Date());
  document.head.appendChild(s);
  s.onload = () => {
    spinner.stop();
    spinner.elem.remove();
  };
}

function loadDisqusIfVisible() {
  if (!window.disqus_enabled) return;

  const disqusElem = document.getElementById('disqus_thread');

  // remove ads
  let observer = new MutationObserver(mutationRecords => {
    for(let record of mutationRecords) {
      if (record.type !== 'childList') {
        return;
      }
      if (!record.addedNodes) {
        return;
      }
      record.addedNodes.forEach(node => {
        if (node.tagName === 'IFRAME' && !node.src.includes('embed/comments')) {
          node.remove();
        }
      });
    }
  });

  observer.observe(disqusElem, {
    childList: true,
  });


  if (document.querySelector(`script[src="${disqusEmbedUrl}"]`)) {
    // already loaded or loading
    return;
  }

  if (disqusElem.hasChildNodes()) return;

  const coords = disqusElem.getBoundingClientRect();

  if (coords.top > document.documentElement.clientHeight + 150 || coords.bottom < -150) {
    // not visible +-150 px
    return;
  }

  loadDisqus();
}

function loadDisqusComments() {
  const disqusElem = document.getElementById('disqus_comments');
  if (!disqusElem) return;

  window.disqus_config();
  disqusElem.dataset.disqusIdentifier = window.page.identifier;

  let s = document.createElement('script');
  s.id = "dsq-count-scr";
  s.src = disqusCountUrl;
  s.setAttribute('data-timestamp', +new Date());
  document.head.appendChild(s);
}

exports.loadDisqus = loadDisqus;
exports.loadDisqusComments = loadDisqusComments;
exports.loadDisqusIfVisible = loadDisqusIfVisible;
