module.exports = class SiteSearch {
  constructor({elem}) {
    this.elem = elem;

    this.formElem = this.elem.querySelector('.sitetoolbar__search');
    this.toggleElem = this.elem.querySelector('.sitetoolbar__search-toggle');
    this.searchInput = this.elem.querySelector('.sitetoolbar__search-input input');
    this.findButton = this.elem.querySelector('.sitetoolbar__find');

    this.toggleElem.onclick = this.onToggleClick.bind(this);
    this.searchInput.onkeydown = this.onSearchInputKeydown.bind(this);
    this.searchInput.onblur = this.onSearchInputBlur.bind(this);
    //this.formElem.onsubmit = this.onFormSubmit.bind(this);
    this.findButton.onpointerdown = this.onFindPointerDown.bind(this);
  }

  onToggleClick() {
    this.show();
  }

  onSearchInputKeydown(event) {
    if (event.keyCode == 27) {
      this.searchInput.value = "";
      this.searchInput.blur();
    }
  };

  onFindPointerDown() {
    if (this.searchInput.value) {
      this.formElem.submit();
    }
  }

  onSearchInputBlur() {
    this.hide();
  }

  show() {
    console.log('show');

    if (!this.formElem.parentNode) {
      this.elem.append(this.formElem);
    }

    let sitetoolbar = document.querySelector('.sitetoolbar');

    // zero-delay needed for the input left animation to start after the form is appended
    setTimeout(() => sitetoolbar.classList.add('sitetoolbar_search_open'));

    this.searchInput.focus();

    let paranja = document.createElement('div');
    paranja.className = 'search-paranja';
    paranja.style.top = sitetoolbar.offsetHeight + 'px';

    document.body.appendChild(paranja);
    document.body.classList.add('paranja-open');
  }

  hide() {
    console.log('hide');
    let paranja = document.querySelector('.search-paranja');
    paranja.remove();

    let sitetoolbar = document.querySelector('.sitetoolbar');

    sitetoolbar.classList.remove('sitetoolbar_search_open');

    document.body.classList.remove('paranja-open');
    this.formElem.remove();
  }
};